<template>
  <div>
    {{ /*verify selected trade modal*/ }}
    <b-modal
      id="tradeModal"
      scrollable
      v-model="verifySelectedTradeModal"
      :title="$t('equity_bond.titles.verify_trade')"
      @hidden="resetTradeModal"
      size="xl"
      :ok-title="$t('equity_bond.modals.verify_trade_modal_ok')"
      :cancel-title="$t('equity_bond.modals.modal_cancel')"
      @ok="tradeOkOperation"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="tradeModal"
      body-class="compact_form_modal"
      footer-class ="compact_modal_footer"
    >
      <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="tradeValidation">
            <b-form>
              <!-- /*******************************Order Details***************************************************************** */ -->
              <b-row class="mt-1">
                <b-card 
                  :title="$t('equity_bond.titles.order_details')" 
                  class="compact_form_card">
                  <b-row>
                      <!-- order date-->
                      <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                        <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.order_date')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.order_date')"
                            rules="required"
                          >
                            <flat-pickr
                              :disabled="true"
                              ref="orderDate"
                              :placeholder="$t('equity_bond.trade_form.order_date')"
                              v-model="tradeForm.orderDate"
                              onClose="testClose"
                              class="form-control compact_form_flatpickr"
                              :config="orderDateConfig"
                              :state="errors.length > 0 ? false : null"
                             
                              
                            />
                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order time-->
                      <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                        <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.order_time')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.order_time')"
                            rules="required"
                          >
                            <cleave
                              :disabled="true"
                              ref="orderTime"
                              id="orderTime"
                              v-model="tradeForm.orderTime"
                              class="form-control compact_form_cleave"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="$t('equity_bond.trade_form.order_time')"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration date -->
                      <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                        <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.order_duration_date')" v-if="orderDurationInputsVisible">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.order_duration_date')"
                          >
                            
                            <flat-pickr
                              :placeholder="$t('equity_bond.trade_form.order_duration_date')"
                              v-model="tradeForm.orderDurationDate"
                              onClose="testClose"
                              class="form-control compact_form_flatpickr"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                              ref="orderDurationDate"
                            
                              
                            />
                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration time -->
                      <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                        <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.order_duration_time')" v-if="orderDurationInputsVisible">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.order_duration_time')"
                          >

                            <cleave
                              id="orderDurationTime"
                              v-model="tradeForm.orderDurationTime"
                              class="form-control compact_form_cleave"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="$t('equity_bond.trade_form.order_duration_time')"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                              ref="orderDurationTime"
                            />

                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    <!-- entry type -->
                    <b-col cols="6" xl="1" lg="1" md="3" sm="6">
                      <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.entry_type')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.entry_type')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.entryType"
                            :options="optEntryTypes"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_entry_type')"
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="entryType"
                            class="compact_form_vue-select_disabled"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- buy/sell -->
                    <b-col cols="6" xl="1" lg="1" md="3" sm="6">
                      <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.buy_sell')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.buy_sell')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.buySell"
                            :options="optbuySells"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_buy_sell')"
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="buySell"
                            class="compact_form_vue-select_disabled"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- order type-->
                    <b-col cols="6" xl="1" lg="1" md="3" sm="6">
                      <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.order_type')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.order_type')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.orderType"
                            :options="optOrderTypes"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_order_type')"
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="orderType"
                            class="compact_form_vue-select_disabled"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- time in force-->
                    <b-col cols="6" xl="1" lg="1" md="3" sm="6">
                      <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.time_in_force')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.time_in_force')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.timeInForce"
                            :options="optTimeInForces"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_time_in_force')"
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="timeInForce"
                            class="compact_form_vue-select_disabled"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                      <!-- enable connected OCO orders-->
                      <b-col cols="12" xl="2" lg="2" md="3" sm="12">
                        <b-form-group class="compact_form_label">
                          <validation-provider
                            #default="{ errors }"
                            name="enable OCO orders"
                          >
                            <b-form-checkbox
                              v-model="tradeForm.enableConnectedOCOOrders"
                              :disabled="true"
                              plain
                              class="compact_form_checkbox"
                            >
                              {{ $t('equity_bond.trade_form.enable_oco') }}
                            </b-form-checkbox>
                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Take Profit Limit -->
                      <b-col cols="4" xl="2" lg="2" md="3" sm="4">
                        <b-form-group class="compact_form_label"
                          :label="$t('equity_bond.trade_form.take_profit_limit')"
                          v-if="tradeForm.enableConnectedOCOOrders"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.take_profit_limit')"
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="takeProfitLimit"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.takeProfitLimit"
                              class="form-control compact_form_cleave"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('equity_bond.trade_form.take_profit_limit')"
                              :disabled="true"
                              ref="takeProfitLimit"
                            />

                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Stop Loss Stop -->
                      <b-col cols="4" xl="2" lg="2" md="3" sm="4">
                        <b-form-group class="compact_form_label"
                          :label="$t('equity_bond.trade_form.stop_loss_stop')"
                          v-if="tradeForm.enableConnectedOCOOrders"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.stop_loss_stop')"
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="stopLossStop"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.stopLossStop"
                              class="form-control compact_form_cleave"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('equity_bond.trade_form.stop_loss_stop')"
                              :disabled="true"
                              ref="StopLoss"
                            />
                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Stop Loss Limit -->
                      <b-col cols="4" xl="2" lg="2" md="3" sm="4">
                        <b-form-group class="compact_form_label"
                          :label="$t('equity_bond.trade_form.stop_loss_limit')"
                          v-if="tradeForm.enableConnectedOCOOrders"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.stop_loss_limit')"
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="stopLossLimit"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.stopLossLimit"
                              class="form-control compact_form_cleave"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('equity_bond.trade_form.stop_loss_limit')"
                              :disabled="true"
                              ref="stopLossLimit"
                            />
                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      
                    <!-- Stop Price -->
                    <b-col cols="6" xl="2" lg="2" md="3" sm="6"
                      v-if="
                        tradeForm.orderType == 'Stop' ||
                          tradeForm.orderType == 'Trailing Stop'
                      "
                    >
                      <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.stop_price')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.stop_price')"
                          :rules="
                            tradeForm.orderType == 'Stop'
                              ? 'required|min_value:0'
                              : '' || tradeForm.orderType == 'Trailing Stop'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <cleave
                            id="stopPrice"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.stopPrice"
                            class="form-control compact_form_cleave"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="$t('equity_bond.trade_form.stop_price')"
                            :disabled="true"
                            ref="stopPrice"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Trailing Amount -->
                    <b-col cols="6" xl="2" lg="2" md="3" sm="6"
                      v-if="tradeForm.orderType == 'Trailing Stop'"
                    >
                      <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.trailing_amount')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.trailing_amount')"
                          :rules="
                            tradeForm.orderType == 'Trailing Stop'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <b-form-input
                            :disabled="true"
                            id="trailingAmount"
                            v-model="tradeForm.trailingAmount"
                            name="trailingAmount"
                            type="number"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('equity_bond.trade_form.trailing_amount')"
                            autofocus
                            ref="trailingAmount"
                            class="compact_form-input"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-row>

                <!-- /******************************* Trade Date & Time && Security Details***************************************************************** */ -->

              <b-row>
                <!-- /******************************* Security Details***************************************************************** */ -->
                <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="pl-0" style="padding-right:0.5rem!important">
                  <!-- /******************************* Security Details***************************************************************** */ -->

                  <b-card
                    :title="$t('equity_bond.titles.security_details')"
                    class="compact_form_card"
                  >
                  <b-row>
                      <!-- cost center -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group class="compact_form_label"
                        :label="$t('equity_bond.trade_form.cost_center')"
                      >
                      <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cost_center')"
                          rules="required"
                        >


                    <v-select
                      :disabled="true"
                      v-model="tradeForm.costCenter"
                      :options="optCostCenters"
                      :clearable="false"
                      :placeholder="$t('equity_bond.trade_form.select_cost_center')"
                      :reduce="(val) => val.CostCenter"
                      label = 'CostCenter'
                      ref="costCenter"
                      class="compact_form_vue-select_disabled"

                    />
                    <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                  </b-form-group>
                    </b-col>



                    <!-- security desc-->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.security_desc')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.security_desc')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.securityDesc"
                            :options="optSecurityDescriptions"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_security_desc')"
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="securityDescription"
                            class="compact_form_vue-select_disabled"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- ISIN -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.isin')">
                        <validation-provider #default="{ errors }" name="ISIN">
                          <b-form-input
                            :disabled="true"
                            id="isin"
                            v-model="tradeForm.isin"
                            :name="$t('equity_bond.trade_form.isin')"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('equity_bond.trade_form.isin')"
                            autofocus
                            ref="isin"
                            class="compact_form-input"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- quantity -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.quantity')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.quantity')"
                          rules="required|min_value:0"
                        >
                          
                          <cleave
                            id="quantity"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.quantity"
                            class="form-control compact_form_cleave"
                            :raw="true"
                            :options="cleaveQuantityOptions.number"
                            :placeholder="$t('equity_bond.trade_form.quantity')"
                            @blur.native="calculateAmount"
                            :disabled="true"
                            ref="quantity"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- client price -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.client_price')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.client_price')"
                            rules="required|min_value:0"
                          >
                            <cleave
                              id="clientPrice"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientPrice"
                              class="form-control compact_form_cleave"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('equity_bond.trade_form.client_price')"
                              :disabled="true"
                              ref="clientPrice"
                            />
                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- client price type ccy -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.price_type')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.price_type')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.clientPriceType"
                              :options="optClientPriceTypes"
                              :clearable="false"
                              :placeholder="$t('equity_bond.trade_form.price_type')"
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                              ref="clientPriceType"
                              class="compact_form_vue-select_disabled"
                            />
                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    <!-- amount -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.amount')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.amount')"
                        >
                          <cleave
                            id="amount"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.amount"
                            class="form-control compact_form_cleave"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="$t('equity_bond.trade_form.amount')"
                            :disabled="true"
                            ref="amount"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- exchange traded -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.exchange_traded')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.exchange_traded')"
                        >
                          <v-select
                            v-model="tradeForm.exchangeTraded"
                            :options="optExchangeTrades"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_exchange_traded')"
                            :reduce="(val) => val"
                            :disabled="true"
                            ref="exchangeTraded"
                            class="compact_form_vue-select_disabled"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- client -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.client')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.client')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.client"
                            :options="optClients"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_client')"
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="client"
                            class="compact_form_vue-select_disabled"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- client account -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.client_account')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.client_account')"
                          rules="required"
                        >
                          <b-form-input
                            :disabled="true"
                            id="clientAccount"
                            v-model="tradeForm.clientAccount"
                            name="clientAccount"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('equity_bond.trade_form.client_account')"
                            ref="Account"
                            class="compact_form-input"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    
                    
                    <!-- counter party -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.counterparty')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.counterparty')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.counterparty"
                            :options="optCounterParties"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_counterparty')"
                            :reduce="(val) => val"
                            :disabled="true"
                            ref="counterparty"
                            class="compact_form_vue-select_disabled"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- cpty account -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.cpty_account')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cpty_account')"
                          rules="required"
                        >
                          <b-form-input
                            :disabled=true
                            id="isin"
                            v-model="tradeForm.cptyAccount"
                            name="cpty account"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('equity_bond.trade_form.cpty_account')"
                            ref="counterpartyAccount"
                            class="compact_form-input"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- counter party trader -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.counterparty_trader')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.counterparty_trader')"
                        >
                          <v-select
                            v-model="tradeForm.counterpartyTrader"
                            :options="optCounterPartyTraders"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_counterparty_trader')"
                            :reduce="(val) => val"
                            :disabled="true"
                            ref="counterpartyTrader"
                            class="compact_form_vue-select_disabled"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- sfc user -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.sfc_user')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.sfc_user')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.scfUser"
                            :options="optScfUsers"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_sfc_user')"
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="tcUser"
                            class="compact_form_vue-select_disabled"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- acting capacity -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.acting_capacity')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.acting_capacity')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.actingCapaticy"
                            :options="optActingCapacities"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_acting_capacity')"
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="actingCapaticy"
                            class="compact_form_vue-select_disabled"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  </b-card>

                  <!-- /******************************* Trade Date & Time***************************************************************** */ -->

                  <b-card
                      :title="$t('equity_bond.titles.trade_date_time')"
                      class="compact_form_card"
                    >
                    <b-row>
                        <!-- Trade Date -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.trade_date')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.trade_date')"
                            >
                            <b-input-group
                              class="compact_form_input-group"
                              :class="
                                tradeForm.tradeDateName == 'Sunday' ||
                                tradeForm.tradeDateName == 'Saturday'
                                  ? 'tradeDateName'
                                  : ''
                              "
                              :append="tradeForm.tradeDateName"
                            >
                              <flat-pickr
                                :placeholder="$t('equity_bond.trade_form.trade_date')"
                                v-model="tradeForm.tradeDate"
                                onClose="testClose"
                                class="form-control compact_form_flatpickr"
                                :config="dateConfig"
                                :disabled="true"
                                ref="tradeDate"
                              />
                            </b-input-group>
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Settlement Date -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.settlement_date')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.settlement_date')"
                            >
                            <b-input-group
                              class="compact_form_input-group"
                              :class="
                                tradeForm.settlementDateName == 'Sunday' ||
                                tradeForm.settlementDateName == 'Saturday'
                                  ? 'settlementDateName'
                                  : ''
                              "
                              :append="tradeForm.settlementDateName"
                            >
                              <flat-pickr
                                :placeholder="$t('equity_bond.trade_form.settlement_date')"
                                v-model="tradeForm.settlementDate"
                                onClose="testClose"
                                class="form-control compact_form_flatpickr"
                                :config="dateConfig"
                                :disabled="true"
                                ref="settlementDate"
                              />
                              </b-input-group>
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- execution time -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.execution_time')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.execution_time')"
                            >
                              <cleave
                                id="executionTime"
                                v-model="tradeForm.executionTime"
                                class="form-control compact_form_cleave"
                                :raw="false"
                                :options="cleaveOptions.time"
                                :placeholder="$t('equity_bond.trade_form.execution_time')"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                                ref="executionTime"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>
                </b-col>


               <!-- /******************************* Trade Details && Settlement Details ***************************************************************** */ -->
               <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="pr-0" style="padding-left:0.5rem!important">
                  

                  <!-- /******************************* Trade Details***************************************************************** */ -->

                    <b-card
                      :title="$t('equity_bond.titles.trade_details')"
                      class="compact_form_card"
                    >
                    <b-row>
                        <!-- Client Rate -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.client_rate')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.client_rate')"
                              :rules="
                                tradeForm.calculateCommissionFromRate1
                                  ? 'min_value:0'
                                  : ''
                              "
                            >
                              <b-input-group append="Bps" class="compact_form_input-group">
                                <b-input-group-prepend is-text>
                                  <b-form-checkbox
                                    plain
                                    name="checkbox-input"
                                    v-model="
                                      tradeForm.calculateCommissionFromRate1
                                    "
                                    v-b-tooltip.hover.top
                                    :title="$t('equity_bond.trade_form.client_rate_tooltip')"
                                    :disabled="true"
                                  >
                                
                                </b-form-checkbox>
                                </b-input-group-prepend>

                                <cleave
                                  id="clientRate"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.clientRate"
                                  class="form-control compact_form_cleave"
                                  :raw="true"
                                  :options="cleave6DecimalOptions.number"
                                  :placeholder="$t('equity_bond.trade_form.client_rate')"
                                  :disabled="true"
                                  ref="commissionRate"
                                />
                              </b-input-group>

                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Client Commission -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.client_comission')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.client_comission')"
                              :rules="
                                !tradeForm.calculateCommissionFromRate1
                                  ? 'min_value:0'
                                  : ''
                              "
                            >
                              <cleave
                                id="clientCommission"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientCommission"
                                class="form-control compact_form_cleave"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="$t('equity_bond.trade_form.client_comission')"
                                :disabled="true"
                                ref="commissionAmount"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Client amount -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.client_amount')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.client_amount')"
                              rules="min_value:0"
                            >
                              <cleave
                                id="clientAmmount"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientAmount"
                                class="form-control compact_form_cleave"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="$t('equity_bond.trade_form.client_amount')"
                                :disabled="true"
                                ref="clientAmount"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Counterparty Rate -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.counterparty_rate')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.counterparty_rate')"
                              :rules="
                                tradeForm.calculateCommissionFromRate2
                                  ? 'min_value:0'
                                  : ''
                              "
                            >
                              <b-input-group append="Bps" class="compact_form_input-group">
                                <b-input-group-prepend is-text>
                                  <b-form-checkbox
                                    plain
                                    name="checkbox-input"
                                    v-model="
                                      tradeForm.calculateCommissionFromRate2
                                    "
                                    v-b-tooltip.hover.top
                                    :title="$t('equity_bond.trade_form.counterparty_rate_tooltip')"
                                    :disabled="true"
                                  />
                                </b-input-group-prepend>

                                <cleave
                                  id="counterpartyRate"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.counterpartyRate"
                                  class="form-control compact_form_cleave"
                                  :raw="true"
                                  :options="cleave6DecimalOptions.number"
                                  :placeholder="$t('equity_bond.trade_form.counterparty_rate')"
                                  :disabled="true"
                                  ref="counterpartyRate"
                                />
                              </b-input-group>

                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- cpty comission -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.cpty_comission')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.cpty_comission')"
                              :rules="
                                !tradeForm.calculateCommissionFromRate2
                                  ? 'min_value:0'
                                  : ''
                              "
                            >
                              <cleave
                                id="cptyComission"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.cptyCommission"
                                class="form-control compact_form_cleave"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="$t('equity_bond.trade_form.cpty_comission')"
                                :disabled="true"
                                ref="counterpartyCommission"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- counterparty amount -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.counterparty_amount')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.counterparty_amount')"
                              rules="min_value:0"
                            >
                              <cleave
                                id="counterpartyAmount"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.counterpartyAmount"
                                class="form-control compact_form_cleave"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="$t('equity_bond.trade_form.counterparty_amount')"
                                :disabled="true"
                                ref="counterpartyAmount"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- profit -->
                        <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                          <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.profit')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.profit')"
                            >
                              <cleave
                                id="profit"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.profit"
                                class="form-control compact_form_cleave"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="$t('equity_bond.trade_form.profit')"
                                :disabled="true"
                                ref="tcProfit"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- order taken via -->
                        <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                          <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.order_taken_via')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.order_taken_via')"
                            >
                              <v-select
                                v-model="tradeForm.orderTakenVia"
                                :options="optOrderTakenVias"
                                :clearable="false"
                                :placeholder="$t('equity_bond.trade_form.select_order_taken_via')"
                                :reduce="(val) => val"
                                :disabled="true"
                                ref="orderTakenVia"
                                class="compact_form_vue-select_disabled"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- order taken through -->
                        <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                          <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.order_given_through')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.order_given_through')"
                            >
                              <v-select
                                v-model="tradeForm.orderGivenThrough"
                                :options="optOrderGivenThroughs"
                                :clearable="false"
                                :placeholder="$t('equity_bond.trade_form.select_order_given_through')"
                                :reduce="(val) => val"
                                :disabled="true"
                                ref="orderGivenThrough"
                                class="compact_form_vue-select_disabled"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- tax -->
                        <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                          <b-form-group class="compact_form_label"
                            :label="$t('equity_bond.trade_form.tax')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.tax')"
                              rules="min_value:0"
                            >
                              <b-form-input
                                id="tax"
                                v-model.number="tradeForm.tax"
                                name="tax"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('equity_bond.trade_form.tax')"
                                autofocus
                                :disabled="true"
                                ref="tax"
                                class="compact_form-input"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- note -->
                        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                          <b-form-group class="compact_form_label">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.note')"
                            >
                              <b-form-textarea
                                id="form-textarea"
                                :placeholder="$t('equity_bond.trade_form.note')"
                                no-resize
                                v-model="tradeForm.note"
                                :disabled="true"
                                ref="traderNote"
                                class="compact_form-textarea"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>

                  <!-- /******************************* Settlement Details***************************************************************** */ -->
                  <b-card
                      :title="$t('equity_bond.titles.settlement_details')"
                      class="compact_form_card"
                    >
                    <b-row>
                        <!-- UTI  -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.uti')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.uti')"
                            >
                              <b-form-input
                                id="UTI"
                                v-model="tradeForm.uti"
                                name="UTI"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('equity_bond.trade_form.uti')"
                                
                                :disabled="true"
                                class="compact_form-input"
                              />

                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Unique Link ID -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.unique_link_id')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.unique_link_id')"
                            >
                              <b-form-input
                                id="uniqueLinkID"
                                v-model.number="tradeForm.uniqueLinkID"
                                type="number"
                                name="uniqueLinkID"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('equity_bond.trade_form.unique_link_id')"
                                
                                :disabled="true"
                                ref="uniqueLinkId"
                                class="compact_form-input"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Trade Ref -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.trade_ref')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.trade_ref')"
                            >
                              <b-form-input
                                :disabled="true"
                                id="tradeRef"
                                v-model="tradeForm.tradeRef"
                                name="tradeRef"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('equity_bond.trade_form.trade_ref')"
                                class="compact_form-input"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                    </b-row>
                    </b-card>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="tradeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" /><b> {{ $t('equity_bond.overlay_messages.verifying_message') }}</b>
            </p>
          </div>
        </template>
      </b-overlay>
      <!-- modal footer -->
      <template #modal-footer>
        <b-row class="d-flex align-items-center" style="flex: 1;">
          <!-- trade actions -->
        <b-col cols="6" md="6" xl="6" lg="6" sm="6" class=" pl-0 d-flex justify-content-start align-items-center">

          <b-button
            id="tradeAction-button"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            size="sm"
            class="float-left mb-0"
            :disabled="actionsData == null || actionsData.length == 0"
          >
            <feather-icon
              icon="EyeIcon"
              size="14"
              style="margin-right: 0.2rem;"
            />

            <span class="text-nowrap">{{ $t('equity_bond.buttons.trade_actions') }}</span>
          </b-button>
          <b-popover
            target="tradeAction-button"
            placement="top"
            triggers="click"
            id="actions-popover"
          >
            <template #title>
              {{ $t('equity_bond.titles.trade_actions') }}
            </template>
            <b-table-simple hover small caption-top responsive bordered>
              <b-tbody class="compact_form_actions-table">
                <b-tr v-for="(action, index) in actionsData" :key="index">
                  <td style="width: 20%;">
                    {{ action.type }}
                  </td>
                  <td style="width: 20%;">
                    {{ action.user }}
                  </td>
                  <td style="width: 30%;">
                    {{ action.date }}
                  </td>
                  <td style="width: 30%;">
                    {{ action.reason }}
                  </td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-popover>
          <!-- changed input message -->
          <p class="d-inline ml-1 mb-0" v-if="changedInputs.length > 0">
          <b-icon
            class="ml-1"
            title=""
            icon="record-fill"
            style="color:orange!important"
            data-toggle="tooltip"
            data-placement="top"
            font-scale="0.5"
          ></b-icon
          ><b style="margin-left:0.3rem;color:#6e6b7b;font-size: 0.7rem;">{{
            $t('equity_bond.modals.inputs_written_red_inputs')
          }}</b>
        </p>
        </b-col>
        <!-- trade ok operation buttons -->
        <b-col cols="6" md="6" xl="6" lg="6" sm="6" class=" pr-0 d-flex justify-content-end align-items-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            size="sm"
            class="float-right mr-1"
            @click="resetTradeModal"
          >
            {{$t('equity_bond.modals.modal_close')}}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right mr-1"
            @click="tradeOkOperation"
          >
          {{ $t('equity_bond.modals.verify_trade_modal_ok') }}
          </b-button>
          
        </b-col>
        </b-row>
      </template>
      {{/****END*** verify for trades modal*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import equityStoreModule from "./EquityStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const EQUITYBONDS_APP_STORE_MODULE_NAME = "equity&bond";
    // Register module
    if (!store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
      store.registerModule(
        EQUITYBONDS_APP_STORE_MODULE_NAME,
        equityStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
        store.unregisterModule(EQUITYBONDS_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {},
  props: {
      
    searchedCounterparties: {
      type: Array,
        default: function() {
        return [];
      }
    },
    searchedClients: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optCostCenters: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optClients: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optCounterParties: {
      type: Array,
        default: function() {
        return [];
      }
    }
},

  data() {
    return {
      allEquityBondsData: [],
      verifySelectedTradeModal: false,
      tradeModalLoading: false,
      orderDurationDataDisabled:true,
      orderDurationTimeDisabled: true,
      orderDurationInputsVisible: true,
      optEntryTypes: ["Order", "Execution"],
      optbuySells: ["Buy", "Sell", "Sell Short"],
      optOrderTypes: ["Limit", "Market", "Stop Limit", "Stop", "Trailing Stop"],
      optTimeInForces: ["DAY", "GTC", "GTD", "FOK", "FAK"],
      optInstrumentTypes: ["EQ", "FI"],
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      optSecurityDescriptions: [],
      optClientPriceTypes: [],
      optExchangeTrades: [],
      optCounterPartyTraders: [],
      optScfUsers: [],
      optActingCapacities: ["PRINCIPAL", "AGENT"],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      fetchedTradeData: {},
      actionsData: null,
      changedInputs: [],

      tradeForm: {
        costCenter : null,

        orderDurationDate: null,
        orderDurationTime: null,
        entryType: null,
        buySell: null,
        orderType: null,
        timeInForce: null,
        orderDate: null,
        orderTime: null,
        enableConnectedOCOOrders: false,
        stopPrice: null,
        trailingAmount: null,
        takeProfitLimit: null,
        stopLossStop: null,
        stopLossLimit: null,
        instrumentType: "EQ",
        securityDesc: null,
        quantity: null,
        clientPrice: null,
        clientPriceType: null,
        amount: null,
        client: null,
        clientAccount: null,
        isin: null,
        exchangeTraded: null,
        counterparty: null,
        cptyAccount: null,
        counterpartyTrader: null,
        scfUser: null,
        actingCapaticy: null,
        tradeDate: null,
        tradeDateName:null,
        settlementDate: null,
        settlementDateName:null,
        executionTime: null,
        clientRate: null,
        clientCommission: null,
        clientAmount: null,
        counterpartyRate: null,
        tax: null,
        cptyCommission: null,
        counterpartyAmount: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        profit: null,
        tradeReportingNeeded: null,
        note: null,
        calculateCommissionFromRate1: false,
        calculateCommissionFromRate2: false,
        uti: null,
        uniqueLinkID: null,
        tradeRef: null,
        tradeStart: null,
        tradeEnd: null,
        settleStart: null,
        settleEnd: null,
      },
      changedValuesForm:{
        costCenter:null,
        orderDurationDate:null,
        orderDurationTime:null,
        entryType: null,
        buySell: null,
        orderType: null,
        timeinForce:null,
        orderDate:null,
        orderTime:null,
        enableOCO: null,
        stopPrice: null,
        trailingAmount:null,
        takeProfitLimit:null,
        stopLoss: null,
        stopLossLimit:null,
        securityDescription:null,
        quantity:null,
        price: null,
        ccy: null,
        amount: null,
        client: null,
        clientAccount: null,
        isin: null,
        exchangeTraded:null,
        counterparty: null,
        counterpartyAccount: null,
        counterpartyTrader: null,
        tcUser: null,
        actingCapaticy:null,
        tradeDate:null,
        settlementDate:null,
        executionTime:null,
        commissionRate:null,
        commissionAmount:null,
        clientAmount:null,
        counterpartyRate:null,
        tax:null,
        counterpartyCommission:null,
        counterpartyAmount:null,
        orderTakenVia:null,
        orderGivenThrough: null,
        tcProfit:null,
        traderNote:null,
        calculateCommissionFromRate1:null,
        calculateCommissionFromRate2:null,
        tcUti:null,
        uniqueLinkId:null
      },

      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i:s",
        time_24hr: true,
      },
      dateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
      },
      orderDateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
        // onChange: function(selectedDates, dateStr, instance) {
        //   console.log(instance)
        // }
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },
      cleave6DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 6,
        },
      },
      cleaveQuantityOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 0,
          numeralIntegerScale: 0,
        },
      },
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["saveLastSearchEquityBond"]),
    getArangeEquityBondTCUsers() {
      store
        .dispatch("equity&bond/fetchArangeEquityBondTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optScfUsers = res.data.users;
            this.getArrangeEquityBondOrderTakenGivenThrough();
          } else if (res.data.info == "no_users") {
            this.errorMessage(this.$t('equity_bond.messages.user_error_no_users'));
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeEquityBondOrderTakenGivenThrough() {
      store
        .dispatch(
          "equity&bond/fetchArrangeEquityBondOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs = res.data.orderGivenThroughs;
            this.optOrderTakenVias = res.data.orderTakenVias;
          } else {
            this.errorMessage(this.$t('equity_bond.messages.not_found_given_order_data'));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeEquityBondCurrencies() {
      store
        .dispatch("equity&bond/fetchArrangeEquityBondCurrencies", this.user)
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optClientPriceTypes = res.data.currencies;
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    tradeOkOperation(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.tradeValidation.validate().then((success) => {
        if (success) {
          this.$checkAccessRight("EquityBond", "Verify").then((res) => {
            if (res.data.valid) {
              if (this.tradeForm.uti != null) {
                  this.tradeModalLoading = true
                store
                  .dispatch("equity&bond/tradeVerifyOperation", {
                    auth: this.user,
                    trade: this.tradeForm,
                  })
                  .then((res) => {
                    if (res.data.info == "Pass") {
                        this.verifiedTradeToastMessage()
                        this.resetTradeModal()
                        this.$emit('verified', true);
                        this.tradeModalLoading = false
                    } else {
                      if(res.data.info == 'verified'){
                        this.checkTradeVerifyOperationLangMessage(res.data.user, res.data.date);
                      } else if(res.data.info == 'user_can_not_verified'){
                        this.anotherUserVerifyMessage()
                      } else {
                        this.checkTradeVerifyOperationMessage(res.data.message);
                      }
                      this.tradeModalLoading = false
                    }
                  })
                  .catch((error) => {
                    this.tradeModalLoading = false
                    console.log(error);
                  });
              } else {
                  this.notFoundUTIValue(10000)
              }
            } else {
              this.notAuthToastMessage();
            }
          });
        } else {
          let notEnteredDatas = "";

          let checkRequiredDatas = _.map(
            this.$refs.tradeValidation.errors,
            (value, key) => ({ key, value })
          );

          if (checkRequiredDatas.length > 0) {
            let first = true;
            checkRequiredDatas.forEach((r) => {
              if (r.value.length > 0) {
                if (first) {
                  notEnteredDatas += '\n' + r.key;
                  first = false;
                } else {
                  notEnteredDatas += ", \n" + r.key;
                  first = false;
                }
              }
            });
            this.checkRequiredInputsMessage(notEnteredDatas, 20000);
          }
        }
      });
      // this.verifySelectedTradeModal = false;
      // this.actionsData = null
    },
    changeTimeInForceType() {
      if(this.tradeForm.timeInForce == 'DAY') {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString()
        this.orderDurationDataDisabled = true;
        this.orderDurationTimeDisabled = true
        this.orderDurationInputsVisible = true
      }else if(this.tradeForm.timeInForce == 'GTC') {
        this.orderDurationInputsVisible = false
        this.tradeForm.orderDurationDate = null
        this.tradeForm.orderDurationTime = null
      } else if(this.tradeForm.timeInForce == 'GTD') {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString()
        this.orderDurationDataDisabled = false;
        this.orderDurationTimeDisabled = false;
        this.orderDurationInputsVisible = true

      }else if(this.tradeForm.timeInForce == 'FOK') {
        this.orderDurationInputsVisible = false
        this.tradeForm.orderDurationDate = null
        this.tradeForm.orderDurationTime = null

      } else if(this.tradeForm.timeInForce == 'FAK') {
        this.orderDurationInputsVisible = false
        this.tradeForm.orderDurationDate = null
        this.tradeForm.orderDurationTime = null

      }
    },
    resetTradeModal() {
      this.verifySelectedTradeModal = false;
      this.fetchedTradeData = {};
      this.actionsData = null;
      this.changedInputs = [];
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t('equity_bond.toast_messages.database_connection_error'),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkTradeVerifyOperationMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTradeVerifyOperationLangMessage(user, date) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("backend_language_message.verify_trade1") + ' ' + user + ' ' + this.$t("backend_language_message.verify_trade_on") + ' ' + date,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    anotherUserVerifyMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("backend_language_message.another_user_verify"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkRequiredInputsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: this.$t('equity_bond.messages.required_fill_inputs') + ` ${message}`,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t('equity_bond.general_messages.not_authorized_message'),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    notFoundUTIValue(timeout) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Found UTI",
          text: this.$t('equity_bond.messages.not_found_uti'),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      },{
          timeout,
        });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    verifiedTradeToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t('equity_bond.toast_messages.trade_verified_success'),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    MDYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("MM-DD-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, val) {
      if (value != "") {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    arrangeInputFormat() {
      if (
        this.tradeForm.clientPrice != null &&
        this.tradeForm.clientPrice > 0
      ) {
        this.tradeForm.clientPrice = Number(this.tradeForm.clientPrice).toFixed(
          2
        );
      }

      // if (this.tradeForm.clientRate != null) {
      //   this.tradeForm.clientRate = this.formatPrice2(this.tradeForm.clientRate, 6)
      // }

      // if (this.tradeForm.counterpartyRate != null) {
      //   this.tradeForm.counterpartyRate = this.formatPrice2(this.tradeForm.counterpartyRate, 6)
      // }

      if (this.tradeForm.clientCommission != null) {
        this.tradeForm.clientCommission = Number(
          this.tradeForm.clientCommission
        ).toFixed(2);
      }

      if (this.tradeForm.cptyCommission != null) {
        this.tradeForm.cptyCommission = Number(
          this.tradeForm.cptyCommission
        ).toFixed(2);
      }

      if (this.tradeForm.amount != null) {
        this.tradeForm.amount = Number(this.tradeForm.amount).toFixed(2);
      }
      if (this.tradeForm.counterpartyAmount != null) {
        this.tradeForm.counterpartyAmount = Number(
          this.tradeForm.counterpartyAmount
        ).toFixed(2);
      }
      if (this.tradeForm.clientAmount != null) {
        this.tradeForm.clientAmount = Number(
          this.tradeForm.clientAmount
        ).toFixed(2);
      }
      if (this.tradeForm.stopLossLimit != null) {
        this.tradeForm.stopLossLimit = Number(
          this.tradeForm.stopLossLimit
        ).toFixed(2);
      }
      if (this.tradeForm.stopLossStop != null) {
        this.tradeForm.stopLossStop = Number(
          this.tradeForm.stopLossStop
        ).toFixed(2);
      }
      if (this.tradeForm.takeProfitLimit != null) {
        this.tradeForm.takeProfitLimit = Number(
          this.tradeForm.takeProfitLimit
        ).toFixed(2);
      }
      if (this.tradeForm.stopPrice != null) {
        this.tradeForm.stopPrice = Number(this.tradeForm.stopPrice).toFixed(2);
      }
      if (this.tradeForm.profit != null) {
        this.tradeForm.profit = Number(this.tradeForm.profit).toFixed(2);
      }
    },
  },
  watch: {
    verifySelectedTradeModal: {
      handler: function(val, before) {
        if (val) {
          

          //for set changes data to style color red
          setTimeout(() => {
            if (this.changedInputs.length > 0) {
              // console.log(this.changedInputs)
              this.changedInputs.forEach((i) => {
                if (this.changedValuesForm.hasOwnProperty(_.camelCase(i.key))) {
                  let refKey = _.camelCase(i.key);
                  
                  if (this.$refs[refKey]) {
                    if(this.$refs[refKey].$el.classList.length > 0 || this.$refs[refKey].$el.classList.indexOf('v-select') == 1){
                      this.$refs[refKey].$el.classList.add('change_selectbox_style') // for v-select box
                      
                    }

                    this.$refs[refKey].$el.style = "color:orange";
                  }
                }
              });
            }
          }, 500);

        }
      },
    },
    fetchedTradeData: {
      handler: function(val, before) {
        if (val.ID != 0) {
          this.tradeForm = {
            costCenter:val.CostCenter,

            orderDurationDate:
              val.OrderDurationDate != null
                ? this.dateFormat(val.OrderDurationDate)
                : null,
            orderDurationTime:
              val.OrderDurationTime != null
                ? val.OrderDurationTime
                : null,
            entryType: val.EntryType ?? null,
            buySell: val.BuySell ?? null,
            orderType: val.OrderType ?? null,
            timeInForce: val.TimeinForce ?? null,
            orderDate:
              val.OrderDate != null ? this.dateFormat(val.OrderDate) : null,
            orderTime:
              val.OrderTime != null ? val.OrderTime : null,
            enableConnectedOCOOrders: val.EnableOCO ? true : false,
            stopPrice: val.StopPrice != null ? parseFloat(val.StopPrice) : null,
            trailingAmount:
              val.TrailingAmount != null ? parseFloat(val.TrailingAmount) : null,
            takeProfitLimit:
              val.TakeProfitLimit != null
                ? parseFloat(val.TakeProfitLimit)
                : null,
            stopLossStop: val.StopLoss != null ? parseFloat(val.StopLoss) : null,
            stopLossLimit:
              val.StopLossLimit != null ? parseFloat(val.StopLossLimit) : null,
            instrumentType: "EQ",
            securityDesc: val.SecurityDescription ?? null,
            quantity: val.Quantity ?? null,
            clientPrice: val.Price != null ? parseFloat(val.Price) : null,
            clientPriceType: val.Ccy ?? null,
            amount: val.Amount != null ? parseFloat(val.Amount) : null,
            client: val.Client ?? null,
            clientAccount: val.Account ?? null,
            isin: val.Isin ?? null,
            exchangeTraded: val.ExchangeTraded ?? null,
            counterparty: val.Counterparty ?? null,
            cptyAccount: val.CounterpartyAccount ?? null,
            counterpartyTrader: val.CounterpartyTrader ?? null,
            scfUser: val.TcUser ?? null,
            actingCapaticy: val.ActingCapacity ?? null,
            tradeReportingNeeded: val.margin == "0" ? false : true,
            tradeDate:
              val.TradeDate != null ? this.dateFormat(val.TradeDate) : null,
            settlementDate:
              val.SettlementDate != null
                ? this.dateFormat(val.SettlementDate)
                : null,
            executionTime:
              val.ExecutionTime != null
                ? val.ExecutionTime
                : null,
            clientRate:
              val.CommissionRate != null ? parseFloat(val.CommissionRate) : null,
            clientCommission:
              val.CommissionAmount != null
                ? parseFloat(val.CommissionAmount)
                : null,
            clientAmount:
              val.ClientAmount != null ? parseFloat(val.ClientAmount) : null,
            counterpartyRate:
              val.CounterpartyRate != null
                ? parseFloat(val.CounterpartyRate)
                : null,
            tax: val.Tax != null ? val.Tax : null,
            cptyCommission:
              val.CounterpartyCommission != null
                ? parseFloat(val.CounterpartyCommission)
                : null,
            counterpartyAmount:
              val.CounterpartyAmount != null
                ? parseFloat(val.CounterpartyAmount)
                : null,
            orderTakenVia: val.OrderTakenVia ?? null,
            orderGivenThrough: val.OrderGivenThrough ?? null,
            profit: val.TcProfit != null ? parseFloat(val.TcProfit) : null,
            tradeReportingNeeded: val.TradeReportingNeeded == 1 ? true : false,
            note: val.TraderNote ?? null,
            calculateCommissionFromRate1:
              val.CalculateCommissionFromRate1 == 1 ? true : false,
            calculateCommissionFromRate2:
              val.CalculateCommissionFromRate2 == 1 ? true : false,
            uti: val.TcUti ?? null,
            uniqueLinkID: val.UniqueLinkId ?? null,
            tradeRef: val.id,
            tradeStart: null,
            tradeEnd: null,
            settleStart: null,
            settleEnd: null,
          };

          // this.tradeForm.orderDurationDate = this.tradeForm.orderDate
          // this.tradeForm.orderDurationTime = moment()
          // .endOf("day")
          // .format("HH:mm:ss")
          // .toString()

          this.changeTimeInForceType()


          //set trade date name
          let tradeDate = new Date(this.MDYdateFormat(this.tradeForm.tradeDate));
        
          this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];
          //set settle date name
          let settleDate = new Date(this.MDYdateFormat(this.tradeForm.settlementDate));
        
          this.tradeForm.settlementDateName = this.weekday[settleDate.getDay()];

          this.arrangeInputFormat();


          // hack textare row count
          setTimeout(function(){
            let textarea = document.getElementById("form-textarea");
            if(textarea){
              textarea.rows = 1;
            }
          },200)
        }
      },
    },
  },
};
</script>

<style>
#tradeModal .modal-header .modal-title {
  color: white !important;
}

.eq_color .vs__dropdown-toggle .vs__selected-options .vs__selected {
  color: #191970;
}

.fi_color .vs__dropdown-toggle .vs__selected-options .vs__selected {
  color: #cd853f;
}

.settlementDateName .input-group-append .input-group-text {
  color: red;
}

.tradeDateName .input-group-append .input-group-text {
  color: red;
}

#actions-popover {
  width: 500px !important;
  max-width: 500px !important;
}




</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.change_selectbox_style {
  .vs__dropdown-toggle{
    .vs__selected-options{
      .vs__selected {
        color:orange
       }
    }
  }
}

</style>
